import { template as template_f1c8a5c954034d77a5e1919c90fa9b25 } from "@ember/template-compiler";
const FKText = template_f1c8a5c954034d77a5e1919c90fa9b25(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
