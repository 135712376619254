import { template as template_2ce753e5ab5b4c4ba365c7ef560e43ca } from "@ember/template-compiler";
const SidebarSectionMessage = template_2ce753e5ab5b4c4ba365c7ef560e43ca(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
