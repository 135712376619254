import { template as template_4529a08267934d239d215b15684d32b7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4529a08267934d239d215b15684d32b7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
